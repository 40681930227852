.businessCell {
  .names {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  svg {
    font-size: 1.2em;
  }
}
