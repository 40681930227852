@import "nlib/config";

.usersTab {
  display: flex;
  flex: 1;
  flex-direction: column;
  .actions {
    display: flex;
    padding-top: $contentDefaultIndent * 2;
    padding-bottom: $contentDefaultIndent * 2;
    background-color: $uiBackgroundColor;
    &.sticky {
      position: sticky;
      z-index: 1;
      top: 0;
    }
    .filters {
      display: flex;
      flex: auto;
      .text {
        flex: 2;
        max-width: 28rem;
      }
      .select {
        flex: 1;
        max-width: 14rem;
        margin-left: $contentDefaultIndent;
      }
      .button {
        margin-left: $contentDefaultIndent;
      }
    }
    .button {
      margin-left: $contentDefaultIndent * 2;
    }
  }
  .mfaCheckbox {
    margin-top: $contentDefaultIndent * 2;
  }
  .nameCell,
  .emailCell,
  .roleCell,
  .statusCell,
  .notificationsCell,
  .lastLoginAtCell,
  .actionsCell {
    overflow: hidden;
    white-space: nowrap;
  }
  .nameCell {
    flex: 10;
    width: 10px;
  }
  .emailCell {
    flex: 10;
    width: 10px;
    .avatar {
      margin-right: $contentDefaultIndent;
    }
    a {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .roleCell {
    flex: 5;
    width: 5px;
  }
  .statusCell {
    flex: 4;
    width: 4px;
  }
  .notificationsCell {
    flex: 3;
    width: 3px;
  }
  .businessCell {
    flex: 8;
    width: 8px;
  }
  .lastLoginAtCell {
    flex: 5;
    width: 5px;
  }
  .actionsCell {
    flex: none;
    width: 7rem;
    padding-right: $contentDefaultIndent;
  }
  .emptyState {
    display: flex;
    flex: auto;
    padding: $contentDefaultIndent * 2;
    border: 1px solid $uiBorderColor;
    border-radius: $uiBorderRadius;
    background-color: $uiWhiteColor;
  }
  .table {
    flex: auto;
    box-shadow: inset 0 0 0 1px $uiBorderColor;
  }
  .tableRow {
    &.pointer {
      cursor: pointer;
    }
    &.currentUser,
    &.pendingUser {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0.25rem;
        bottom: 0.25rem;
        left: 0;
        width: 3px;
        border-radius: 3px;
      }
    }
    &.currentUser {
      &::before {
        background-color: $uiPrimaryColor;
      }
    }
    &.pendingUser {
      &::before {
        background-color: $uiAlternativeColor;
      }
    }
  }
}
