@import "nlib/config";

.googleBackupButton {
  margin-left: $contentDefaultIndent;
  img {
    width: 1.2em;
    margin-right: 0.3em;
  }
  .circle {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: 0.3em;
    border-radius: 50%;
    background-color: currentColor;
  }
}
