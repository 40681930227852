@import "nlib/config";

.businessesTab {
  display: flex;
  flex: 1;
  flex-direction: column;
  .actions {
    display: flex;
    padding-top: $contentDefaultIndent * 2;
    padding-bottom: $contentDefaultIndent * 2;
    background-color: $uiBackgroundColor;
    &.sticky {
      position: sticky;
      z-index: 1;
      top: 0;
    }
    .filters {
      display: flex;
      flex: auto;
      .text {
        flex: 2;
        max-width: 28rem;
      }
      .select {
        flex: 1;
        max-width: 14rem;
        margin-left: $contentDefaultIndent;
      }
      .button {
        margin-left: $contentDefaultIndent;
      }
    }
    .button {
      margin-left: $contentDefaultIndent * 2;
    }
  }
  .table {
    flex: auto;
    box-shadow: inset 0 0 0 1px $uiBorderColor;
  }
  .selected {
    position: relative;
    background-color: rgba($uiPrimaryColor, 0.05);
    &::before {
      content: "";
      position: absolute;
      top: $contentDefaultIndent;
      bottom: $contentDefaultIndent;
      left: 0;
      width: 3px;
      border-radius: 3px;
      background-color: $uiPrimaryColor;
    }
  }
  .usersCell {
    white-space: nowrap;
  }
  .nameCell,
  .documentsCell {
    overflow: hidden;
    white-space: nowrap;
    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .nameCell {
    flex: 10;
    width: 10px;
    img {
      width: 1.75rem;
      margin-right: $contentDefaultIndent;
    }
    .content {
      .name {}
    }
  }
  .usersCell {
    flex: 20;
    width: 20px;
  }
  .documentsCell {
    flex: 5;
    width: 5px;
  }
  .actionsCell {
    flex: none;
    width: 7rem;
    padding-right: $contentDefaultIndent;
  }
  .emptyState {
    display: flex;
    flex: auto;
    padding: $contentDefaultIndent * 2;
    border: 1px solid $uiBorderColor;
    border-radius: $uiBorderRadius;
    background-color: $uiWhiteColor;
  }
  .tableRow {
    cursor: pointer;
  }
}
