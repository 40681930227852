@import "nlib/config";

.settingsPage {
  .actions {
    display: flex;
    width: 100%;
    .superAdminActions {
      display: flex;
      padding-right: $contentDefaultIndent;
      button {
        + button {
          margin-left: $contentDefaultIndent;
        }
      }
    }
    .backButton {
      margin-left: auto;
    }
  }
  .tabs {
    .tab {
      span {
        margin-right: 0.5em;
      }
    }
  }
}
