@import "nlib/config";

.nameCell {
  .avatar {
    margin-right: $contentDefaultIndent;
  }
  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    > svg {
      color: $uiWarningColor;
      font-size: 1rem;
    }
  }
}
