@import "nlib/config";

.actionsCell {
  display: flex;
  button {
    flex: auto;
    height: auto;
    padding: $contentDefaultIndent / 2 0;
    background: none;
    svg {
      font-size: 1rem;
    }
  }
}
