@import "nlib/config";

.copyInviteLinkModal {
  .link {
    padding: $contentDefaultIndent $contentDefaultIndent * 1.5;
    overflow: hidden;
    border-radius: $uiBorderRadius;
    background-color: $uiBackgroundColor;
    code {
      padding: 0;
      color: $uiDarkColor;
    }
  }
}
